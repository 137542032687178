import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Schedule } from '../../models/schedule';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-schedule-edit',
  templateUrl: './schedule-edit.component.html',
  styleUrls: ['./schedule-edit.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ScheduleEditComponent implements OnInit {
  schedule: Schedule;
  cp = [
    { label: '無', value: null },
    { label: 'VFA (5:0)', value: 1 },
    { label: 'VIN (5:0)', value: 2 },
    { label: 'VCA (5:0)', value: 3 },
    { label: 'VSU (4:0)', value: 4 },
    { label: 'VSU1 (4:1)', value: 5 },
    { label: 'VPO (3:0)', value: 6 },
    { label: 'VPO1 (3:1)', value: 7 },
    { label: 'VFO (5:0)', value: 8 },
    { label: 'DSQ (5:0)', value: 9 },
    { label: '2DSQ (0:0)', value: 10 },
  ];
  winnerChoice = [
    { label: '無', value: null },
  ];

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig,
              public http: HttpClient) { }

  ngOnInit() {
    this.schedule = this.config.data.schedule;
    if (this.schedule.S_PID1) {
      this.winnerChoice.push({
        label: this.schedule.N_PLAYERNAME1,
        value: this.schedule.S_PID1
      });
    }
    if (this.schedule.S_PID2) {
      this.winnerChoice.push({
        label: this.schedule.N_PLAYERNAME2,
        value: this.schedule.S_PID2
      });
    }
  }

  update() {
    if (this.schedule.winner === this.schedule.S_PID1) {
      this.schedule.loser = this.schedule.S_PID2;
    } else if (this.schedule.winner === this.schedule.S_PID2) {
      this.schedule.loser = this.schedule.S_PID1;
    }

    this.http.post('api/updateschedule', this.schedule).subscribe(
      result => {
        this.ref.close(true);
      },
      error => {
        this.ref.close(false);
      }
    );
  }
}
