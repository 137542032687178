import {Component, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/api';
import {AppService} from '../../services/app.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';

@Component({
    selector: 'app-excel-dialog',
    templateUrl: './excel-dialog.component.html',
    styleUrls: ['./excel-dialog.component.css']
})

export class ExcelDialogComponent implements OnInit {
    regForm: object;
    players: object[] = [];

    constructor(
        private appService: AppService,
        private http: HttpClient,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig) {
    }

    ngOnInit() {
        this.regForm = this.config.data.reg_form;
        console.log(this.regForm);
    }

    uploadHandler(event) {
        const formData = new FormData();
        for (const k of Object.keys(this.regForm)) {
            if (!this.regForm[k]) {
                continue;
            }
            formData.set(k, this.regForm[k]);
        }
        formData.append('excel', event.files[0]);

        this.http.post<any>('./api/regforms_excel', formData, {
            observe: 'response',
            responseType: 'json'
        }).subscribe(
            res => {
                const result = {
                    success: true,
                    data: res.body || '上傳成功', // body contains an array of players
                };
                this.ref.close(result);
            },
            (errorRes: HttpErrorResponse) => {
                console.log('1', errorRes);
                const result = {
                    success: false,
                    data: errorRes.error.message || '上傳失敗',
                };
                this.ref.close(result);
            });
    }
}
