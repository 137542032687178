import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

import { DialogService } from 'primeng/api';

import { AppService } from '../../services/app.service';

import { PlayerDialogComponent } from '../../components/player-dialog/player-dialog.component';

import { ExcelDialogComponent } from '../../components/excel-dialog/excel-dialog.component';

@Component({
    selector: 'app-reg-form',
    templateUrl: './reg-form.component.html',
    styleUrls: ['./reg-form.component.css'],
    providers: [DialogService]
})
export class RegFormComponent implements OnInit, OnDestroy {
    private req: any;
    userInfo: any = {};
    reg_form: any = {};
    regs: any = [];
    memberList: any;

    games: any = {};
    register_btn_label = '我要報名';
    leader_tooltip: string;
    phone_tooltip: string;
    email_tooltip: string;

    selectedGame: any;

    dialogDisplay = false;
    dialogMessage: string;

    isRegister = false;
    isPay = false;

    cols: any[];


    @ViewChild('leader', { static: false }) leader_field: ElementRef;
    @ViewChild('phone', { static: false }) phone_field: ElementRef;
    @ViewChild('email', { static: false }) email_field: ElementRef;

    constructor(private _service: AppService, public dialogService: DialogService) { }

    ngOnInit() {
        this.cols = [
            { field: 'N_GRADETYPENAME', header: '量級組別 ' },
            { field: 'N_GRADENAME', header: '量級名稱' },
            { field: 'N_PLAYERNAME', header: '姓名' },
            { field: 'S_PID', header: '身分證字號' },
            { field: 'I_GENDER', header: '性別' },
            { field: 'member_name', header: '學校/單位' },
            { field: 'T_BIRTHDAY', header: '出生年月日' }
        ];

        this.req = this._service.list('userinfo').subscribe(data => {
            this.userInfo = data;
            console.log(data);
        });

        this.req = this._service.list('games').subscribe(data => {
            this.games = data;
        });

        this.req = this._service.list('members').subscribe(data => {
            this.memberList = data;
        });
    }

    register() {
        if (!this.selectedGame) {
            this.dialogDisplay = true;
            this.dialogMessage = '請選擇要報名的賽事';
            return;
        }

        this.isRegister = true;
    }

    add() {
        const ref = this.dialogService.open(PlayerDialogComponent, {
            header: '新增隊員',
            width: '60%',
            data: {
                players: this.reg_form.players,
                selectedGame: this.selectedGame,
                memberList: this.memberList,
            },
        });

        ref.onClose.subscribe((player) => {
            console.log('player', player);
            if (player) {
                player.member = this.userInfo.gpid;
                player.member_name = this.userInfo.gpname;
                this.reg_form.players.push(player);
            }
        });
    }

    gameChange() {
        const request = {
          user_id: this.userInfo.user_id,
          game_id: this.selectedGame.id
        };

        this.register_btn_label = '我要報名';
        this.regs = [];
        this.reg_form = {};

        this.req = this._service.get('regforms', request).subscribe(data => {
            this.regs = data;
            if (this.regs.length > 0) {
                this.reg_form = this.regs[0];
                this.isPay = this.reg_form.I_PAYSTATUS === '1';
                this.register_btn_label = '修改資料';
            } else {
                this.reg_form = {
                  user_id: this.userInfo.user_id,
                  game_id: this.selectedGame.id,
                  players: []
                };
            }
        });
    }

    onRowDelete(row) {
        const index = this.reg_form.players.indexOf(row, 0);
        if (index > -1) { this.reg_form.players.splice(index, 1); }

    }

    upload() {
        if (!this.validate()) { return; }

        console.log(this.reg_form);

        this.req = this._service.post('regforms', this.reg_form).subscribe(data => {
            this.dialogDisplay = true;
            this.dialogMessage = data.message;

            this.gameChange();
        });
    }

    excelUpload() {
        if (!this.validateRegForm()) {
            return;
        }

        const ref = this.dialogService.open(ExcelDialogComponent, {
            header: '以 Excel 新增隊員',
            width: '60%',
            data: {
                reg_form: this.reg_form,
            },
        });

        ref.onClose.subscribe((result: {success: boolean, data: any}) => {
            if (result === undefined) {
                return;
            }

            if (result.success) {
                this.reg_form.players.push(...result.data);
            } else {
                this.dialogDisplay = true;
                this.dialogMessage = result.data;
            }
        });
    }

    cancel() {
        this.isRegister = false;
        this.gameChange();
    }

    validateRegForm(): boolean {
        if (!this.reg_form.N_LEADER || this.reg_form.N_LEADER === '') {
            this.leader_tooltip = '請填寫領隊姓名';
            this.leader_field.nativeElement.focus();
            return false;
        }

        if (!this.reg_form.S_CONNECTORPHONE || this.reg_form.S_CONNECTORPHONE === '') {
            this.phone_tooltip = '請填寫聯絡電話';
            this.phone_field.nativeElement.focus();
            return false;
        }

        if (!this.reg_form.S_CONNECTOREMAIL || this.reg_form.S_CONNECTOREMAIL === '') {
            this.email_tooltip = '請填寫聯絡EMAIL';
            this.email_field.nativeElement.focus();
            return false;
        }

        return true;
    }

    validate(): boolean {
        if (this.validateRegForm() === false) {
            return false;
        }

        if (!this.reg_form.players || this.reg_form.players.length === 0) {
            this.dialogDisplay = true;
            this.dialogMessage = '請至少新增一筆隊員資料';
            return false;
        }

        return true;
    }

    clear() {
        this.leader_tooltip = undefined;
        this.phone_tooltip = undefined;
        this.email_tooltip = undefined;
    }

    ngOnDestroy() {
        this.req.unsubscribe();
    }
}
