import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-game-result',
  templateUrl: './game-result.component.html',
  styleUrls: ['./game-result.component.css']
})
export class GameResultComponent implements OnInit {
  games: any = [];
  gameGradeTypeResults: GameGradeResult[] = [];

  selectedGame: any = undefined;
  selectedGameGradeType: any = undefined;

  constructor(private service: AppService, public http: HttpClient) { }

  ngOnInit() {
    this.service.list('games').subscribe(data => {
      this.games = data;
    });
  }

  onGameChange() {
    this.selectedGameGradeType = undefined;
  }

  onGameGradeTypeChange() {
    this.getGradeTypeResults().subscribe((results) => {
      this.gameGradeTypeResults = results;
      console.log(results);
    });
  }

  getGradeTypeResults(): Observable<GameGradeResult[]> {
    const param = {
      game_id: this.selectedGame.id,
      game_gradetype_id: this.selectedGameGradeType.id,
    };
    return this.http.get<GameGradeResult[]>('api/getgamegradetyperesult', { params: param });
  }

  print() {
    const gameId = this.selectedGame.id;
    const gameGradetypeId = this.selectedGameGradeType.id;
    window.open(`/api/getgamegradetyperesult?game_id=${gameId}&game_gradetype_id=${gameGradetypeId}&excel=true`);
  }
}

class GameGradeResult {
  gradeName: string;
  rank: {
    name: string;
    member: string;
  }[];
}
