import { Component, OnInit, OnDestroy } from '@angular/core';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-reg-player',
    templateUrl: './reg-player.component.html',
    styleUrls: ['./reg-player.component.css']
})
export class RegPlayerComponent implements OnInit, OnDestroy {

    private req: any;

    resultMessage: string = '';

    games: any = [];
    players: any = [];

    selectedGame: any = undefined;
    selectedGameGradeType: any = undefined;
    selectedGameGrade: any = undefined;

    cols: any[];
    constructor(private _service: AppService) { }

    ngOnInit() {
        this.cols = [
            { field: 'N_GRADETYPENAME', header: '量級組別 ' },
            { field: 'N_GRADENAME', header: '量級名稱' },
            { field: 'N_PLAYERNAME', header: '姓名' },
            { field: 'S_PID', header: '身分證字號' },
            { field: 'I_GENDER', header: '性別' },
            { field: 'T_BIRTHDAY', header: '出生年月日' }
        ];

        this.req = this._service.list('games').subscribe(data => {
            this.games = data;
        });
    }

    onGameChange() {

        this.selectedGameGradeType = undefined;
        this.players = [];
    }

    onGameGradeTypeChange() {
        this.players = [];
    }

    onGameGradeChange() {

        console.log(this.selectedGame, this.selectedGameGrade)
        let request = {
            'game_id': this.selectedGame.id,
            'game_grade_id': this.selectedGameGrade.id
        }

        this.resultMessage = '';
        this.players = [];
        this.req = this._service.get('getplayers', request).subscribe(data => {
            this.players = data;

            if (data.length > 0) {
                this.resultMessage = `查詢結果 (總數: ${data.length})`
            }
            else
                this.resultMessage = '查無資料!'
        });
    }


    ngOnDestroy() {
        this.req.unsubscribe();
    }

}
