import { Component, Directive, OnDestroy, OnInit } from '@angular/core';

import {forkJoin, Observable} from 'rxjs';

import { AppService } from '../../services/app.service';
import {HttpClient} from '@angular/common/http';
import {Schedule} from '../../models/schedule';
import * as _ from 'lodash';
import { DialogService } from 'primeng/api';
import { ExcelDialogComponent } from '../../components/excel-dialog/excel-dialog.component';
import { ScheduleEditComponent } from '../../components/schedule-edit/schedule-edit.component';


@Component({
    selector: 'app-reg-schedule',
    templateUrl: './reg-schedule.component.html',
    styleUrls: ['./reg-schedule.component.css'],
    providers: [DialogService],
})
export class RegScheduleComponent implements OnInit {
    private req: any;

    games: any = [];
    scheduleModes: [number, string][];
    schedules: Schedule[] = [];
    schedulesByMode: {[mode: number]: Schedule[]};

    selectedGame: any = undefined;
    selectedGameGradeType: any = undefined;
    selectedGameGrade: any = undefined;
    selectedMode: number;
    selectedSchedules: Schedule[][] = [];

    dialogDisplay = false;
    dialogMessage: string;

    selectedRadio: string;
    selectedTab = 0;

    editMode = false;

    cols: any[];

    constructor(private service: AppService, public http: HttpClient,
                public dialogService: DialogService) { }

    ngOnInit() {
        this.selectedRadio = '0';
        this.cols = [
            { field: 'mat_serial', header: '編號' },
            { field: 'N_PLAYERNAME1', header: '選手A' },
            { field: 'r1_p1_score', header: '1回分數' },
            { field: 'r1_p1_attack', header: '1回攻擊' },
            { field: 'r1_p1_success_times', header: '1回成功' },
            { field: 'r1_p1_failure_times', header: '1回犯規' },
            { field: 'r1_p1_caveat_times', header: '1回警告' },
            { field: 'r2_p1_score', header: '2回分數' },
            { field: 'r2_p1_attack', header: '2回攻擊' },
            { field: 'r2_p1_success_times', header: '2回成功' },
            { field: 'r2_p1_failure_times', header: '2回犯規' },
            { field: 'r2_p1_caveat_times', header: '2回警告' },

            { field: 'N_PLAYERNAME2', header: '選手B' },
            { field: 'r1_p2_score', header: '1回分數' },
            { field: 'r1_p2_attack', header: '1回攻擊' },
            { field: 'r1_p2_success_times', header: '1回成功' },
            { field: 'r1_p2_failure_times', header: '1回犯規' },
            { field: 'r1_p2_caveat_times', header: '1回警告' },
            { field: 'r2_p2_score', header: '2回分數' },
            { field: 'r2_p2_attack', header: '2回攻擊' },
            { field: 'r2_p2_success_times', header: '2回成功' },
            { field: 'r2_p2_failure_times', header: '2回犯規' },
            { field: 'r2_p2_caveat_times', header: '2回警告' },

            { field: 'T_FIRST_ENDTIME', header: '1回時間' },
            { field: 'T_SECOND_ENDTIME', header: '2回時間' },
            { field: 'S_WINNERNAME', header: '勝出' },
        ];

        this.req = this.service.list('games').subscribe(data => {
            this.games = data;
        });
    }

    onGameChange() {
        this.selectedGameGradeType = undefined;
        this.selectedGameGrade = undefined;
        this.selectedRadio = '0';
        this.selectedTab = 0;
        this.schedules = [];
    }

    onGameGradeTypeChange() {
        this.selectedGameGrade = undefined;
        this.selectedRadio = '0';
        this.selectedTab = 0;
        this.schedules = [];
    }

    onGameGradeChange() {
        this.editMode = false;
        this.selectedRadio = '0';
        this.selectedTab = 0;
        this.schedules = [];
        this.getScheduled();
    }

    onScheduleEdited() {
        this.getScheduled();
    }

    onTabChange(e) {
        this.selectedTab = e.index;
    }

    getScheduled() {
        const observable = forkJoin([
            this.getSchedule(),
            this.getScheduleModes(),
        ]);
        observable.subscribe(
            value => {
                console.log(value);
                [this.schedules, this.scheduleModes] = value;

                // filter schedules with byes
                this.schedules = _.filter(this.schedules, (s) => {
                    return !(s.S_PID2 === null && s.winner);
                });

                this.schedulesByMode = _(this.schedules)
                    .groupBy('L_GAMEMODE')
                    .value();
                this.selectGameMode(_.toNumber(this.selectedRadio), false);
            },
            error => { console.log(error); }
        );
    }

    getScheduleModes(): Observable<any> {
        const param = {
            game_id: this.selectedGame.id,
            game_grade_id: this.selectedGameGrade.id,
        };
        return this.http.get('api/getschedule_modes', {params: param});
    }

    getSchedule(): Observable<Schedule[]> {
        const param = {
            game_id: this.selectedGame.id,
            game_grade_id: this.selectedGameGrade.id,
        };
        return this.http.get<Schedule[]>('api/getschedules', {params: param});
    }

    selectGameMode(index, resetTab: boolean) {
        if (resetTab) {
            this.selectedTab = 0;
        }

        this.selectedMode = _.get(this.scheduleModes[index], '0');
        if (this.selectedMode === undefined) {
            return;
        }

        const schedules = this.schedulesByMode[this.selectedMode];
        const schedulesByLevel = _.groupBy(schedules, 'L_SELFLEVEL');
        this.selectedSchedules = _(schedulesByLevel).keys().sortBy().map(x => schedulesByLevel[x]).value();
    }

    toggleEdit() {
        this.editMode = !this.editMode;
    }

    editSchedule(schedule) {
        const ref = this.dialogService.open(ScheduleEditComponent, {
            header: '編輯賽程結果',
            width: '60%',
            baseZIndex: 9999,
            styleClass: 'dialog',
            data: {
                schedule: _.assign({}, schedule),
            },
        });

        ref.onClose.subscribe((success) => {
            if (success === true) {
                this.dialogDisplay = true;
                this.dialogMessage = '賽程更新成功';
                this.onScheduleEdited();
            } else if (success === false) {
                this.dialogDisplay = true;
                this.dialogMessage = '賽程更新失敗';
            }
        });
    }
}
