import { Component, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Schedule } from '../../models/schedule';
import * as _ from 'lodash';
import { SortableOptions } from 'sortablejs';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { MenuItem } from 'primeng/components/common/menuitem';
import * as moment from 'moment';

interface UpcomingSchedule {
  game: number;
  group: number;
  order: number;
  schedule: Schedule;
}

interface GroupOpt {
  order: number;
  name: string;
}

@Component({
  selector: 'app-upcoming-game',
  templateUrl: './upcoming-game.component.html',
  styleUrls: ['./upcoming-game.component.css'],
  providers: [
    MessageService, ConfirmationService
  ]
})
export class UpcomingGameComponent implements OnInit {
  groupLabel = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  games: any = [];
  dates: SelectItem[];
  schedules: UpcomingSchedule[] = [];
  groups: Schedule[][] = [];

  selectedGroupOption: GroupOpt;
  groupOptions: GroupOpt[];

  unqueuedSchedules: {
    gradeId: number
    gradeName: string
    schedules: Schedule[]
  }[] = [];

  showAddSchedule = false;
  showAddScheduleDate = false;

  menuItems: MenuItem[] = [];

  selectedGame: any = undefined;
  selectedDate: string;
  selectedSchedules: UpcomingSchedule[] = [];
  selectedGrades = [];

  formDate: Date;
  formNGroup: string;

  sortOption: SortableOptions;

  constructor(private service: AppService, public http: HttpClient, private messageService: MessageService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.service.list('games').subscribe(data => {
      this.games = data;
    });

    this.sortOption = {
      group: 'AB',
      onUpdate: (event: any) => {
      }
    };

    this.menuItems = [
      {
        label: '新增賽程',
        icon: 'pi pi-plus',
        command: (event) => {
          this.showAddSchedule = true;
        },
      },
      {
        label: '上傳',
        icon: 'pi pi-cloud-upload',
        command: (event) => {
          this.uploadSchedule();
        }
      },
      {
        label: '清除本日賽程',
        icon: 'pi pi-undo',
        command: (event) => {
          this.resetSchedule();
        }
      },
    ];
  }

  onGameChange() {
    this.schedules = [];
    this.groups = [];
    this.selectedGrades = [];

    const observable = forkJoin([
      this.getUpcomingSchedule(),
      this.getUnqueuedSchedule(),
    ]);

    observable.subscribe((value) => {
        let unqueued: Schedule[];
        [this.schedules, unqueued] = value;

        console.log('schedules', this.schedules);
        console.log('unqueued', unqueued);

        const schedulesByDate = _(this.schedules)
          .groupBy('date')
          .value();
        this.dates = _(schedulesByDate)
          .keys()
          .orderBy()
          .map((d) => {
            return { label: d, value: d };
          })
          .value();

        this.unqueuedSchedules = _(unqueued)
          .groupBy('N_GRADENAME')
          .map((v, k) => {
            return {
              gradeId: _.get(v[0], 'N_GRADEID'),
              gradeName: k,
              schedules: v
            };
          })
          .value();

        if (this.selectedDate) {
          this.onDateChange();
        }
      },
      error => { console.log(error); }
    );
  }

  onDateChange() {
    this.selectedSchedules = _(this.schedules)
      .groupBy('date')
      .get(this.selectedDate);
    const upcomingGroups: UpcomingSchedule[][] = _(this.selectedSchedules).groupBy('group').toPairs().sortBy(0)
      .map((x) => {
        return x[1] || [];
      }).value();
    this.groups = _(upcomingGroups).map((schedules) => {
      const sortedSchedules = _(schedules)
        .sortBy('order')
        .sortBy(s => {
          if (s.schedule.winner) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(s => s.schedule).value() || [];
      return sortedSchedules || [];
    }).value();
    this.groupOptions = _(this.groups).map<GroupOpt>((group, idx) => {
      return {
        order: idx + 1,
        name: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[idx]
      };
    }).value();
    this.groupOptions.splice(0, 0, {
      order: 0,
      name: '平均分配',
    });

    console.log('groups', this.groups);
  }

  getUpcomingSchedule(): Observable<UpcomingSchedule[]> {
    const param = {
      game_id: this.selectedGame.id,
    };
    return this.http.get<UpcomingSchedule[]>('api/upcoming_schedule', {params: param});
  }

  getUnqueuedSchedule(): Observable<Schedule[]> {
    const param = {
      game_id: this.selectedGame.id,
    };
    return this.http.get<Schedule[]>('api/unqueued_schedule', {params: param});
  }

  addScheduleDate() {
    this.selectedGrades = [];
    this.showAddScheduleDate = true;
  }

  showToast(severity: 'success' | 'error', detail: string) {
    this.messageService.add({
      severity,
      detail,
      summary: '系統訊息',
    });
  }

  formValid(): boolean {
    if (!this.formDate) {
      return false;
    }
    if (!this.formNGroup) {
      return false;
    }
    if (this.selectedGrades.length === 0) {
      return false;
    }
    return true;
  }

  sendForm() {
    this.showAddScheduleDate = false;

    const body = {
      game_id: this.selectedGame.id,
      date: moment(this.formDate).format().split('T')[0],
      n_groups: this.formNGroup,
      grades: _(this.selectedGrades).map(x => x.gradeId).value(),
    };

    return this.http.post('api/upcoming_schedule', body).subscribe(
      () => {
        this.onGameChange();
        this.showToast('success', '上傳成功');
      },
      () => {
        this.showToast('error', '上傳失敗');
      }
    );
  }

  addScheduleFormValid(): boolean {
    return this.selectedGrades.length !== 0;
  }

  sendAddScheduleForm() {
    this.showAddSchedule = false;

    const body = {
      game_id: this.selectedGame.id,
      date: this.selectedDate,
      grades: _(this.selectedGrades).map(x => x.gradeId).value(),
      group_opt: this.selectedGroupOption.order,
    };

    console.log('grades', _(this.selectedGrades).map(x => x.gradeId).value());

    return this.http.post('api/add_upcoming_schedule', body).subscribe(
      () => {
        this.onGameChange();
        this.showToast('success', '上傳成功');
      },
      () => {
        this.showToast('error', '上傳失敗');
      }
    );
  }

  uploadSchedule() {
    const schedules = [];
    _.each(this.groups, (group, gid) => {
      group = _.sortBy(group, (s) => {
        return s.winner ? 0 : 1;
      });

      _.each(group, (s, idx) => {
        schedules.push({
          schedule_id: s.id,
          group: gid + 1,
          order: idx + 1,
        });
      });
    });

    const body = {
      schedules,
      game_id: this.selectedGame.id,
      date: this.selectedDate,
    };

    this.http.post('api/update_upcoming_schedule', body).subscribe(
      () => {
        this.onGameChange();
        this.showToast('success', '上傳成功');
      },
      () => {
        this.showToast('error', '上傳失敗');
      }
    );
  }

  cssStyle(): string {
    if (this.groups.length === 0) {
      return '50%';
    }
    return `${100 / this.groups.length}%`;
  }

resetSchedule() {
    this.confirmationService.confirm({
      header: '確定要清除本日的賽程？',
      message: '這將會清除本日賽程的所有場次編號',
      acceptLabel: '確定',
      rejectLabel: '取消',
      accept: () => {
        const body = {
          game_id: this.selectedGame.id,
          date: this.selectedDate,
        };
        return this.http.delete('api/upcoming_schedule', { params: body }).subscribe(
          () => {
            this.onGameChange();
            this.showToast('success', '賽程已清除');
          },
          () => {
            this.showToast('error', '賽程清除失敗');
          }
        );
      }
    });
  }
}
