import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

import * as _ from 'lodash';

import { Schedule } from '../../models/schedule';
import { HttpClient } from '@angular/common/http';

import domToImg from 'dom-to-image';

declare const Treant: any;

@Component({
  selector: 'app-schedules-render',
  templateUrl: './schedules-render.component.html',
  styleUrls: ['./schedules-render.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SchedulesRenderComponent implements AfterViewInit, OnInit {
  @Input() schedules: Schedule[];
  @Input() players: any[];
  @Input() id: string;
  @Input() scheduleMode: number;
  @Input() gameId: number;
  @Input() gameGradeId: number;
  @Input() gameName: string;
  @Input() gradeTypeName: string;
  @Input() gradeName: string;
  @Output() scheduleCreated = new EventEmitter<Error>();

  hasSchedules: boolean;
  groupRoster: any[];
  rounds: any = {};  // { level: { player_id: { other_player, schedule } } }
  sortedRounds: any[] = [];

  treant: any;
  treantWidth: any;
  treantOverflowX: any;

  constructor(public http: HttpClient) { }

  ngOnInit() {
    console.log(`mode ${this.scheduleMode}`, this.schedules);
    this.hasSchedules = this.schedules && this.schedules.length !== 0;

    if (!this.isEliminationGame()) {
      const groupRosterIDs = _(this.schedules)
        .map<[number, number]>((s) => {
          return [s.S_PID1, s.S_PID2];
        })
        .flatten()
        .uniq()
        .value();
      const playersByID = _.keyBy(this.players, 'id');
      _.forEach(playersByID, (player) => {
        player.wins = 0;
        player.cp = 0;
      });
      this.groupRoster = _(groupRosterIDs).map(id => playersByID[id]).sortBy('drawNumber').value();

      _.forEach(this.schedules, (s) => {
        if (this.rounds[s.L_SELFLEVEL] === undefined) {
          this.rounds[s.L_SELFLEVEL] = {};
        }
        this.rounds[s.L_SELFLEVEL][s.S_PID1] = {
          opponent: playersByID[s.S_PID2],
          schedule: s,
          cp: s.p1_cp,
          tp: s.r1_p1_score + s.r2_p1_score,
        };
        this.rounds[s.L_SELFLEVEL][s.S_PID2] = {
          opponent: playersByID[s.S_PID1],
          schedule: s,
          cp: s.p2_cp,
          tp: s.r1_p2_score + s.r2_p2_score,
        };

        if (s.S_PID1 === s.winner) {
          playersByID[s.S_PID1].wins += 1;
        }
        if (s.S_PID2 === s.winner) {
          playersByID[s.S_PID2].wins += 1;
        }

        playersByID[s.S_PID1].cp += s.p1_cp;
        playersByID[s.S_PID2].cp += s.p2_cp;
      });

      this.sortedRounds = _.sortBy(this.rounds);

      console.log('sorted rounds', this.sortedRounds);
    }
  }

  ngAfterViewInit() {
    if (!this.hasSchedules) {
      return;
    }

    if (this.isEliminationGame()) {
      this.renderEliminationBracket();
    }
  }

  isEliminationGame(): boolean {
    if (_.includes([0, 1, 2, 3, 110, 130], this.scheduleMode)) {
      return true;
    } else if (_.includes([100, 101, 102], this.scheduleMode)) {
      return false;
    } else {
      throw new Error('invalid schedule mode');
    }
  }

  createSchedule() {
    const param = {
      game_id: this.gameId,
      game_grade_id: this.gameGradeId,
      game_mode: this.scheduleMode,
    };
    this.http.post('api/setschedulemode', param).subscribe(
      (result: any) => {
        console.log(result);
        this.scheduleCreated.emit(null);
      },
      (errorResp) => {
        console.log(errorResp);
        this.scheduleCreated.emit(new Error(errorResp.error.message));
      }
    );
  }

  renderEliminationBracket() {
    this.treant = new Treant({
      chart: {
        container: `#${this.id}`,
        rootOrientation: 'EAST',
        node: {
          drawLineThrough: true
        },
        levelSeparation: 25,
        connectors: {
          type: 'step',
          style: {
            'stroke-width': 2,
            stroke: '#ccc',
          }
        }
      },
      nodeStructure: this.buildBracketNodes(),
    });
    console.log('treant', this.treant);
  }

  private buildBracketNodes() : any {
    let nodes: {[id: string]: any} = {};

    // We want to build the tree from root to leaf and since schedules is already sorted by (self_level, seq),
    // we work from the back to the front.
    _.forEachRight(this.schedules, (schedule) => {
      let id = `${schedule.L_SELFLEVEL}-${schedule.L_SEQ}`;
      let p1 = _.find(this.players, _.matchesProperty('id', schedule.S_PID1));
      let p2 = _.find(this.players, _.matchesProperty('id', schedule.S_PID2));

      nodes[id] = {
        text: {
          name: schedule.S_WINNERNAME || '', 
          title: '', 
          desc: schedule.mat_serial || '', 
        },
        HTMLclass: 'node-non-leaf',
        children: [
          {
            text: this.getNodeText(p1, true, schedule),
            HTMLclass: 'node-leaf',
            player: p1,
          },
          {
            text: this.getNodeText(p2, false, schedule),
            HTMLclass: 'node-leaf',
            player: p2,
          }
        ],
        schedule: schedule,
      }

      let parentNode = nodes[`${schedule.L_PARENTLEVEL}-${schedule.L_PARENTSEQ}`];
      if (parentNode) {
        parentNode.children[(schedule.L_SEQ + 1) % 2] = nodes[id];
      }
    });

    if (!this.isSemiFinals) {
      this.trimEmptyNodes(nodes);
    }

    let lastSchedule = this.schedules[this.schedules.length - 1];
    let rootNodeID = `${lastSchedule.L_SELFLEVEL}-${lastSchedule.L_SEQ}`;
    return nodes[rootNodeID];
  }

  private trimEmptyNodes(nodes: {[id: string]: any}) {
    _.forEach(nodes, (node) => {
      node.children = _.filter(node.children, (child) => {
        return !(child.HTMLclass == 'node-leaf' && !child.player)
      });
      if (node.children.length === 1) {
        delete node.text.desc;
      }
    });
  }

  private get isSemiFinals() : boolean {
    return this.scheduleMode == 110;
  }

  private getNodeText(player: any, isPlayerOne: boolean, schedule: Schedule) : any {
    if (this.isSemiFinals) {
      if (!player) {
        if (schedule.L_SELFLEVEL == 1) {
          var name
          if (schedule.L_SEQ == 1) {
            name = isPlayerOne ? 'A冠' : 'B亞';
          } else {
            name = isPlayerOne ? 'A亞' : 'B冠';
          }
          return {name: name, title: '', desc: ''}
        }
      }
    }

    return {
      name: _.get(player, 'name', ''), 
      title: _.get(player, 'drawNumber', ''), 
      desc: _.get(player, 'memberName', ''),
    }
  }

  print() {
    const element = document.getElementById(this.id);
    const header = document.getElementById('header');

    if (this.treant && this.players.length >= 32) {
      this.treantWidth = '2000px';
      this.treantOverflowX = 'unset';
      this.treant.tree.initJsonConfig.chart.levelSeparation = 140;
      this.treant.tree.reload();
    }

    setTimeout(() => {
      Promise.all([
        domToImg.toSvg(header),
        domToImg.toSvg(element),
      ]).then(([headerUrl, dataUrl]) => {
        const w = window.open('', 'Print', 'width=793,height=1122'); // A4 size
        w.document.body.style.width = '21cm';
        w.document.body.style.height = '29.7cm';

        const headerImg = w.document.createElement('img');
        headerImg.src = headerUrl;
        headerImg.style.width = '100%';
        w.document.body.appendChild(headerImg);

        const img = w.document.createElement('img');
        img.src = dataUrl;
        img.style.maxWidth = '100%';
        img.style.maxHeight = '90%';
        w.document.body.appendChild(img);
        setTimeout(() => {
          w.print();
          w.close();
        }, 1000);

        this.treantWidth = 'auto';
        this.treantOverflowX = 'auto';
        setTimeout(() => {
          this.treant.tree.initJsonConfig.chart.levelSeparation = 25;
          this.treant.tree.reload();
        });
      });
    });
  }
}
