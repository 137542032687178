import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';

// primeng
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { InputTextModule } from 'primeng/inputtext';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { MenuModule } from 'primeng/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { RegFormComponent } from './page/reg-form/reg-form.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { PlayerDialogComponent } from './components/player-dialog/player-dialog.component';
import { RegResultComponent } from './page/reg-result/reg-result.component';
import { RegPlayerComponent } from './page/reg-player/reg-player.component';
import { RegScheduleComponent } from './page/reg-schedule/reg-schedule.component';
import { ExcelDialogComponent } from './components/excel-dialog/excel-dialog.component';
import { RegSchedulePlanComponent } from './page/reg-schedule-plan/reg-schedule-plan.component';
import { SchedulesRenderComponent } from './components/schedules-render/schedules-render.component';
import { SchedulesDialogComponent } from './components/schedules-dialog/schedules-dialog.component';
import { GameResultComponent } from './page/game-result/game-result.component';
import { UpcomingGameComponent } from './page/upcoming-game/upcoming-game.component';
import { ScheduleEditComponent } from './components/schedule-edit/schedule-edit.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { MenubarModule } from 'primeng/menubar';
import { UpcomingGamePublicComponent } from './page/upcoming-game-public/upcoming-game-public.component';
import { SplitButtonModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    AppComponent,
    RegFormComponent,
    NavbarComponent,
    PlayerDialogComponent,
    RegResultComponent,
    RegPlayerComponent,
    RegScheduleComponent,
    ExcelDialogComponent,
    RegSchedulePlanComponent,
    SchedulesRenderComponent,
    SchedulesDialogComponent,
    GameResultComponent,
    UpcomingGameComponent,
    ScheduleEditComponent,
    UpcomingGamePublicComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'csrftoken',
      headerName: 'X-CSRFToken'
    }),
    NoopAnimationsModule,

    FontAwesomeModule,

    DropdownModule,
    TabViewModule,
    InputTextModule,
    DynamicDialogModule,
    ButtonModule,
    CheckboxModule,
    DialogModule,
    TooltipModule,
    InputSwitchModule,
    TableModule,
    ToolbarModule,
    CalendarModule,
    ConfirmDialogModule,
    RadioButtonModule,

    AppRoutingModule,
    FileUploadModule,
    ToastModule,
    MenuModule,
    SortablejsModule.forRoot({ animation: 150 }),
    MenubarModule,
    SplitButtonModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: [
    PlayerDialogComponent,
    ExcelDialogComponent,
    SchedulesDialogComponent,
    ScheduleEditComponent,
  ]
})
export class AppModule {
}
