import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { DynamicDialogRef } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';

@Component({
    selector: 'app-player-dialog',
    templateUrl: './player-dialog.component.html',
    styleUrls: ['./player-dialog.component.css']
})

export class PlayerDialogComponent implements OnInit {
    player: any = {};
    players: any;
    memberList: {id: number, N_GPNAME: string}[];

    selectedGame: any;
    selectedGameGradeType: any;
    selectedGameGrade: any;
    selectedGender: any;

    name_tooltip: string;
    pid_tooltip: string;

    genders = [
        { I_GENDER: '1', value: '男' },
        { I_GENDER: '0', value: '女' }
    ];

    @ViewChild('name', { static: true }) name_field: ElementRef;
    @ViewChild('pid', { static: true }) pid_field: ElementRef;
    @ViewChild('member', { static: true }) memberField: Dropdown;

    constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

    ngOnInit() {
        this.players = this.config.data.players ? this.config.data.players : [];
        this.selectedGame = this.config.data.selectedGame;
        this.memberList = this.config.data.memberList;

        // set dropdown default value
        if (this.selectedGame) {
            this.selectedGameGradeType = this.selectedGame.game_gradetype[0] ? this.selectedGame.game_gradetype[0] : undefined;

            if (this.selectedGameGradeType) {
                this.selectedGameGrade = this.selectedGameGradeType.game_grade[0] ? this.selectedGameGradeType.game_grade[0] : undefined;
            }
        }

        this.selectedGender = this.genders[0];
    }

    confirm() {
        if (!this.validate()) { return; }

        // extract GameGradeType
        this.player.game_gradetype = this.selectedGameGradeType.id;
        this.player.N_GRADETYPENAME = this.selectedGameGradeType.N_GRADETYPENAME;

        // extract GameGrade
        this.player.game_grade = this.selectedGameGrade.id;
        this.player.N_GRADENAME = this.selectedGameGrade.N_GRADENAME;

        // extract Gender
        this.player.I_GENDER = this.selectedGender.I_GENDER;

        // birth format
        const birth = this.player.T_BIRTHDAY;
        if (birth) {
            this.player.T_BIRTHDAY = birth.getFullYear() + '-'
              + (birth.getMonth() + 1).toString().padStart(2, 0) + '-'
              + birth.getDate().toString().padStart(2, '0');
        }

        this.ref.close(this.player);
    }

    validate(): boolean {
        if (!this.player.N_PLAYERNAME || this.player.N_PLAYERNAME === '') {
            return false;
        }

        // check S_PID
        if (!this.player.S_PID || this.player.S_PID === '') {
            return false;
        }

        // let duplicate = this.players.filter(e => e.S_PID === this.player.S_PID);

        // if (duplicate.length > 0) {
        //     this.pid_tooltip = '身份證字號重複';
        //     this.pid_field.nativeElement.focus();
        //     return false;
        // }
        return true;
    }

    clear() {
        this.name_tooltip = undefined;
        this.pid_tooltip = undefined;
    }
}
