import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppService } from '../../services/app.service';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { Schedule } from '../../models/schedule';
import * as _ from 'lodash';
import { MessageService, SelectItem } from 'primeng/api';

interface UpcomingSchedule {
  game: number;
  group: number;
  order: number;
  schedule: Schedule;
}

@Component({
  selector: 'app-upcoming-game-public',
  templateUrl: './upcoming-game-public.component.html',
  styleUrls: ['./upcoming-game-public.component.css'],
  providers: [
    MessageService
  ]
})
export class UpcomingGamePublicComponent implements OnInit, OnDestroy {
  groupLabel = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  games: any = [];
  dates: SelectItem[];
  schedules: UpcomingSchedule[] = [];
  groups: Schedule[][] = [];

  selectedGame: any = undefined;
  selectedDate: string;
  selectedSchedules: UpcomingSchedule[] = [];
  selectedGrades = [];

  timer: any;
  refreshTime: number;
  countdownTime: number | null;

  constructor(private service: AppService, public http: HttpClient, private messageService: MessageService) { }

  ngOnInit() {
    this.service.list('games').subscribe(data => {
      this.games = data;
    });

    this.refreshTime = 5;
  }

  ngOnDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  onGameChange() {
    this.schedules = [];
    this.groups = [];
    this.selectedGrades = [];

    const observable = forkJoin([
      this.getUpcomingSchedule(),
    ]);

    observable.subscribe((value) => {
        [this.schedules] = value;

        console.log('schedules', this.schedules);

        const schedulesByDate = _(this.schedules)
          .groupBy('date')
          .value();
        this.dates = _(schedulesByDate)
          .keys()
          .orderBy()
          .map((d) => {
            return { label: d, value: d };
          })
          .value();

        if (this.selectedDate) {
          this.onDateChange();
        }
      },
      error => { console.log(error); }
    );
  }

  onDateChange() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    if (this.refreshTime < 5) {
      this.refreshTime = 5;
    }

    this.countdownTime = this.refreshTime;
    this.timer = setInterval(() => {
      this.countdownTime -= 1;
      if (this.countdownTime < 0) {
        this.countdownTime = this.refreshTime;
        this.onGameChange();
      }
    }, 1000);

    this.selectedSchedules = _(this.schedules)
      .groupBy('date')
      .get(this.selectedDate);
    const upcomingGroups: UpcomingSchedule[][] = _(this.selectedSchedules).groupBy('group').toPairs().sortBy(0)
      .map((x) => {
        return x[1] || [];
      }).value();
    this.groups = _(upcomingGroups).map((schedules) => {
      const sortedSchedules = _(schedules)
        .sortBy('order')
        .sortBy(s => {
          if (s.schedule.winner) {
            return 1;
          } else {
            return 0;
          }
        })
        .map(s => s.schedule).value() || [];
      return sortedSchedules || [];
    }).value();

    console.log('groups', this.groups);
  }

  onRefreshTimeChange(event) {
    console.log('refresh', event);

    this.refreshTime = Number(event.target.value);
    if (this.refreshTime < 5) {
      this.refreshTime = 5;
    }

    this.countdownTime = this.refreshTime;

    if (this.timer) {
      clearInterval(this.timer);
    } else {
      return;
    }

    console.log('rt', this.refreshTime);

    this.timer = setInterval(() => {
      this.countdownTime -= 1;
      if (this.countdownTime < 0) {
        this.countdownTime = this.refreshTime;
        this.onGameChange();
      }
    }, 1000);
  }

  getUpcomingSchedule(): Observable<UpcomingSchedule[]> {
    const param = {
      game_id: this.selectedGame.id,
    };
    return this.http.get<UpcomingSchedule[]>('api/upcoming_schedule', {params: param});
  }

  showToast(severity: 'success' | 'error', detail: string) {
    this.messageService.add({
      severity,
      detail,
      summary: '系統訊息',
    });
  }

  cssStyle(): string {
    if (this.groups.length === 0) {
      return '50%';
    }
    return `${100 / this.groups.length}%`;
  }
}
