import { Component, OnInit, OnDestroy } from '@angular/core';

import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { DialogService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-reg-result',
    templateUrl: './reg-result.component.html',
    styleUrls: ['./reg-result.component.css'],
    providers: [ConfirmationService, DialogService]
})
export class RegResultComponent implements OnInit, OnDestroy {

    private req: any;

    faSearch = faSearch;
    loading: boolean;
    dialogDisplay: boolean = false;
    dialogMessage: string;

    games: any = [];
    cols: any[];
    regs: any = [];

    selectedGame: any = undefined;
    filterPaid = false;

    constructor(private _service: AppService, public confirmationService: ConfirmationService, public dialogService: DialogService) { }


    ngOnInit() {
        this.cols = [
            { field: 'username', header: '帳號 ' },
            { field: 'gpkind_name', header: '會員名稱' },
            { field: 'game_name', header: '賽事名稱' },
            { field: 'N_LEADER', header: '領隊' },
            { field: 'S_CONNECTORPHONE', header: '聯絡電話' },
            { field: 'I_PAYSTATUS', header: '繳費狀態' }
        ];

        this.req = this._service.list('games').subscribe(data => {
            this.games = data;
        });
    }

    getRegs() {
        this.loading = true;
        this.regs = [];

        const request: any = {
            gameid: this.selectedGame.id,
        };

        if (this.filterPaid) {
            request.ispaid = 1;
        }

        this.req = this._service.get('getregs', request).subscribe(data => {
            this.regs = data;

            this.loading = false;
        });
    }

    // onChange(checked) {

    //     if (checked) {
    //         let request = {
    //             'ispaid': checked ? 1 : 0
    //         }
    //         this.loading = true;
    //         this.regs = [];
    //         this.req = this._service.get('getregs', request).subscribe(data => {

    //             this.regs = data;

    //             console.log('onChange', this.regs, this.regs.length)
    //             this.loading = false;
    //         });
    //     }
    //     else {
    //         this.getRegs()
    //     }
    // }

    setPaid(row) {

        this.confirmationService.confirm({
            icon: undefined,
            header: '執行確認',
            message: '確認是否將此筆表名表標示為 「已繳費」?',
            acceptLabel: '確認',
            rejectLabel: '取消',
            accept: () => {
                let request = {
                    'id': row.id,
                    'ispaid': 1
                }

                this.req = this._service.post('getregs', request).subscribe(data => {

                    row.I_PAYSTATUS = '1';

                    this.dialogDisplay = true;
                    this.dialogMessage = data.message;
                });
            }
        });
    }

    ngOnDestroy() {
        this.req.unsubscribe();
    }

}
