import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { RegFormComponent } from './page/reg-form/reg-form.component';
import { RegResultComponent } from './page/reg-result/reg-result.component';
import { RegPlayerComponent } from './page/reg-player/reg-player.component';
import { RegScheduleComponent } from './page/reg-schedule/reg-schedule.component';
import { RegSchedulePlanComponent } from './page/reg-schedule-plan/reg-schedule-plan.component';
import { GameResultComponent } from './page/game-result/game-result.component';
import { UpcomingGameComponent} from './page/upcoming-game/upcoming-game.component';
import { UpcomingGamePublicComponent } from './page/upcoming-game-public/upcoming-game-public.component';

const routes: Routes = [
    { path: 'reg_form', component: RegFormComponent },
    { path: 'reg_result', component: RegResultComponent },
    { path: 'reg_player', component: RegPlayerComponent },
    { path: 'reg_schedule', component: RegScheduleComponent },
    { path: 'reg_schedule_plan', component: RegSchedulePlanComponent },
    { path: 'game_result', component: GameResultComponent },
    { path: 'upcoming_game', component: UpcomingGameComponent },
    { path: 'upcoming_game_public', component: UpcomingGamePublicComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
