import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

const endpoint = 'api/';
// const endpoint = '/assets/json/';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class AppService {

    private sensorData = new BehaviorSubject(null);

    constructor(private http: HttpClient) { }

    list(url: string) {
        // return this.http.get<DistributeItem[]>(this)
        return this.http.get(endpoint + url);
        // return this.http.get('/assets/json/' + url);
    }

    get(url: string, slugs: object): Observable<any> {
        let queryString;
        for (let key in slugs) {
            if (queryString === undefined)
                queryString = `?${key}=${slugs[key]}`
            else
                queryString += `&${key}=${slugs[key]}`

        }
        console.log("services.get(): " + endpoint + url + queryString);
        return this.http.get<any>(endpoint + url + queryString).pipe(
            catchError(this.handleError('AppService.get()'))
        )
    }


    post(url: string, request: any): Observable<any> {
        return this.http.post<any>(endpoint + url, request, httpOptions).pipe(
            tap(data => { },
                error => {
                    catchError(this.handleError<any>('AppService.add()'))
                })
        );
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.log('error', error)
            if (error.error instanceof ErrorEvent) {
                console.error('An error occurred:', error.error.message);
            }
            else {
                console.error(
                    `Backend returned code ${error.status}, ` +
                    `body was: ${error.error}`);

            }
            return of(result as T);
        }
    }
}
