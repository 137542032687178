import { HttpClient } from '@angular/common/http';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import * as _ from 'lodash';
import {ConfirmationService, DialogService, MenuItem, MessageService} from 'primeng/api';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { forkJoin, from, Observable } from 'rxjs';

import { AppService } from '../../services/app.service';
import { Schedule } from '../../models/schedule';
import { SchedulesDialogComponent } from '../../components/schedules-dialog/schedules-dialog.component';
import { SchedulesRenderComponent } from '../../components/schedules-render/schedules-render.component';

@Component({
  selector: 'app-reg-schedule-plan',
  templateUrl: './reg-schedule-plan.component.html',
  styleUrls: ['./reg-schedule-plan.component.css'],
  providers: [ConfirmationService, DialogService, MessageService],
})
export class RegSchedulePlanComponent implements OnInit {
  games: any = [];
  players: {
    id: string,
    pid: string,
    name: string,
    drawNumber: number|null
    memberName: string,
    paid: boolean,
  }[] = [];
  playersByID: any = {};
  drawSim: any = {};

  schedules: Schedule[] = [];
  scheduleModes: [number, string][];
  schedulesByMode: {[mode: number]: Schedule[]};

  selectedGame: any = undefined;
  selectedGameGradeType: any = undefined;
  selectedGameGrade: any = undefined;
  selectedTab = 0;

  dialogDisplay = false;
  dialogMessage: string;

  menuItems: MenuItem[];

  cols: any[];

  renderComponent: SchedulesRenderComponent;

  @ViewChildren(SchedulesRenderComponent)
  children: QueryList<SchedulesRenderComponent>;

  constructor(private service: AppService, public http: HttpClient,
              private messageService: MessageService, public dialogService: DialogService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {
    this.cols = [
      { field: 'L_SEQ', header: '編號' },
      { field: 'N_PLAYERNAME1', header: '選手A' },
      { field: 'player1_draw_num', header: '籤號' },
      { field: 'N_PLAYERNAME2', header: '選手B' },
      { field: 'player2_draw_num', header: '籤號' },
    ];

    this.service.list('games').subscribe(data => {
      this.games = data;
    });
  }

  onGameChange() {
    this.selectedGameGradeType = undefined;
    this.selectedGameGrade = undefined;
    this.schedules = [];
    this.scheduleModes = [];
    this.schedulesByMode = {};
    this.players = [];
  }

  onGameGradeTypeChange() {
    this.selectedGameGrade = undefined;
    this.schedules = [];
    this.scheduleModes = [];
    this.schedulesByMode = {};
    this.players = [];
  }

  onGameGradeChange(keepTab = false) {
    this.schedules = [];
    this.scheduleModes = [];
    this.schedulesByMode = {};
    this.players = [];
    this.selectedTab = 0;

    if (this.selectedGameGrade === undefined) {
      return;
    }

    const observable = forkJoin([
      this.getSchedule(),
      this.getScheduleModes(),
      this.getPlayers(),
      this.getDraw(),
    ]);
    observable.subscribe(
      value => {
        console.log(value);
        [this.schedules, this.scheduleModes, this.players, this.drawSim] = value;

        this.sortPlayers();

        for (const p of this.players) {
          this.playersByID[p.id] = p;
        }

        if (this.canCreateSchedule()) {
          let paidPlayerNumbers = 0;
          _.forEach(this.players, (p) => {
            if (p.paid) {
              paidPlayerNumbers += 1;
            }
          });

          this.menuItems = [{
            label: '舊淘汰賽',
            command: () => { this.createSchedule(true, false, paidPlayerNumbers); }
          }];

          if (paidPlayerNumbers <= 7) {
            this.menuItems.push({
              label: '循環賽',
              command: () => { this.createSchedule(false, false, paidPlayerNumbers); }
            });
          } else {
            this.menuItems.push({
              label: '新淘汰賽',
              command: () => { this.createSchedule(true, true, paidPlayerNumbers); }
            });
          }
        }

        this.schedulesByMode = _(this.schedules)
          .groupBy('L_GAMEMODE')
          .value();
      },
      error => { console.log(error); }
    );
  }

  onTabChange(event) {
    this.selectedTab = event.index;
    setTimeout(() => {
      const components = this.children.toArray();
      this.renderComponent = components.find(x => {
        return x.id === 'schedules-render-' + (this.selectedTab - 1)
      });
    });
  }

  getSchedule(): Observable<Schedule[]> {
    const param = {
      game_id: this.selectedGame.id,
      game_grade_id: this.selectedGameGrade.id,
    };
    return this.http.get<Schedule[]>('api/getschedules', {params: param});
  }

  getScheduleModes(): Observable<any> {
    const param = {
      game_id: this.selectedGame.id,
      game_grade_id: this.selectedGameGrade.id,
    };
    return this.http.get('api/getschedule_modes', {params: param});
  }

  createSchedule(forceElimination: boolean, newVersion: boolean, numPaid) {
    const numUnpaid = this.players.length - numPaid;
    this.confirmationService.confirm({
      header: '確定要建立賽程？',
      message: `建立賽程時，只有已繳費之選手會列入賽程（尚有 ${numUnpaid} 人未繳費）。`,
      acceptLabel: '確定',
      rejectLabel: '取消',
      accept: () => {
        const param: any = {
          game_id: this.selectedGame.id,
          game_grade_id: this.selectedGameGrade.id,
        };
        if (forceElimination) {
          param.game_mode = 0;
          if (newVersion) {
            param.new_version = 1; // 新淘汰賽
          } else {
            param.new_version = 0; // 舊淘汰賽
          }
        }
        // console.log(param);
        this.http.get('api/setschedules', {params: param}).subscribe(
          (result: any) => {
            console.log(result);
            this.onGameGradeChange();
            this.showToast('success', '賽程建立成功');
          },
          error => {
            console.log(error);
            this.showToast('error', '賽程建立失敗');
          },
        );
      },
    });
  }

  refresh() {
    this.onGameGradeChange();
  }

  onScheduleCreated(event) {
    console.log('schedule created');
    if (event) {
      this.showToast('error', event.message);
    } else {
      this.showToast('success', '賽程安排成功');
    }
    this.onGameGradeChange();
  }

  printSchedule() {
    const selectMode = this.scheduleModes[this.selectedTab - 1];

    const ref = this.dialogService.open(SchedulesDialogComponent, {
      header: '列印賽程表',
      width: '85%',
      baseZIndex: 9999,
      contentStyle: {
        'max-height': '85vh',
        overflow: 'auto',
      },
      data: {
        schedules: this.schedulesByMode[selectMode[0]],
        players: this.players,
      },
    });
  }

  getPlayers(): Observable<any> {
    const param = {
      game_id: this.selectedGame.id,
      game_grade_id: this.selectedGameGrade.id,
    };

    return this.http.get('api/getplayers', {params: param}).pipe(
      mergeMap((playerList: any[]) => from(playerList)),
      map(x => {
        return {
          id: x.id,
          name: x.N_PLAYERNAME,
          drawNumber: x.draw_number,
          pid: x.S_PID,
          memberName: x.member_name,
          paid: x.pay_status === 1,
        };
      }),
      toArray(),
    );
  }

  getDraw(): Observable<any> {
    const param = {
      game_id: this.selectedGame.id,
      game_grade_id: this.selectedGameGrade.id,
    };

    return this.http.get('api/player_draw_sim', {params: param});
  }

  sortPlayers() {
    // Sort by draw number. If player doesn't have draw number, place in front.
    this.players = _.sortBy(this.players, [
      (p) => {
        if (p.drawNumber === null) {
          return -1;
        } else {
          return p.drawNumber;
        }
      },
    ]);
  }

  draw(player) {
    const data = {
      player_draw: {
        [player.id]: this.drawSim[player.id],
      }
    };
    this.http.post('api/player_draw_set', data).subscribe(
      (result: any) => {
        player.drawNumber = result.message[player.id];
        this.sortPlayers();
      },
      error => { console.log(error); }
    );
  }

  drawAll() {
    const data = {
      player_draw: {}
    };
    _.forEach(this.players, (p) => {
      if (!p.drawNumber) {
        data.player_draw[p.id] = this.drawSim[p.id];
      }
    });
    this.http.post('api/player_draw_set', data).subscribe(
      () => {
        this.onGameGradeChange();
        this.showToast('success', '抽籤完畢');
      },
      error => {
        console.log(error);
        this.showToast('error', '抽籤失敗');
      }
    );
  }

  canCreateSchedule(): boolean {
    if (this.players.length === 0) {
      return false;
    }

    for (const p of this.players) {
      if (p.drawNumber === null) {
        return false;
      }
    }
    return this.schedules.length === 0;
  }

  canDrawAll(): boolean {
    for (const p of this.players) {
      if (p.drawNumber === null) {
        return true;
      }
    }
    return false;
  }

  canResetDraw(): boolean {
    if (this.schedules.length !== 0) {
      return false;
    }

    for (const p of this.players) {
      if (p.drawNumber !== null) {
        return true;
      }
    }
    return false;
  }

  resetDraw() {
    this.confirmationService.confirm({
      header: '確定要刪除所有已抽的籤號？',
      message: '若賽事已建立，則無法刪除籤號',
      acceptLabel: '確定',
      rejectLabel: '取消',
      accept: () => {
        const param = {
          game_id: this.selectedGame.id,
          game_grade_id: this.selectedGameGrade.id,
        };
        this.http.post('api/player_draw_reset', param).subscribe(
          (resp: any) => {
            this.onGameGradeChange();
            this.showToast('success', resp.message);
          },
          error => {
            this.showToast('error', error.error.message);
          }
        );
      },
    });
  }

  resetSchedule() {
    this.confirmationService.confirm({
      header: '確定要重置此組別的所有賽事？',
      message: '這將刪除此組別的所有排定賽事及賽事結果，此動作將無法復原！',
      acceptLabel: '確定',
      rejectLabel: '取消',
      accept: () => {
        const param = {
          game_id: this.selectedGame.id,
          game_grade_id: this.selectedGameGrade.id,
        };
        this.http.post('api/clearschedules', param).subscribe(
          (resp: any) => {
            this.onGameGradeChange();
            this.showToast('success', resp.message);
          },
          error => {
            this.showToast('error', error.error.message);
          }
        );
      },
    });
  }

  showToast(severity: 'success' | 'error', detail: string) {
    this.messageService.add({
      severity,
      detail,
      summary: '系統訊息',
    });
  }
}

