import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';

import { faCube, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import { AppService } from '../../services/app.service';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {

    private req: any;
    userInfo: any = {};

    navbarOpen = false;

    title = '中華民國角力協會';
    faCube = faCube;
    faSignOutAlt = faSignOutAlt;

    constructor(private _service: AppService) { }

    ngOnInit() {
        this.req = this._service.list('userinfo').subscribe(data => {
            this.userInfo = data;
        });
    }

    toggleNavbar() {
        this.navbarOpen = !this.navbarOpen;
    }

    ngOnDestroy() {
        this.req.unsubscribe();
    }
}
