import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/api';

import * as _ from 'lodash';
import { Schedule } from '../../models/schedule';

@Component({
  selector: 'app-schedules-dialog',
  templateUrl: './schedules-dialog.component.html',
  styleUrls: ['./schedules-dialog.component.css']
})
export class SchedulesDialogComponent implements OnInit {
  schedules: Schedule[];
  players: any[];
  playersByID;

  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) { }

  ngOnInit() {
    this.schedules = _.filter(this.config.data.schedules, (s) => {
      // don't show seed player
      if (!!s.winner && !s.loser) {
        return false;
      }
      return true;
    });
    this.players = this.config.data.players;
    this.playersByID = _.groupBy(this.players, 'id');
  }

  playerName(id): string {
    return _.get(this.playersByID[id], '[0].name', '-');
  }
}
